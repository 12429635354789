// src/config/index.js

var config = {
  env: 'production',
  baseUrl: 'https://fsdbird.ink',
  redirectUri: 'https://fsdbird.ink/slack/oauth_redirect',
  slackClientId: '7981670975651.7996002064801',
  slackClientSecret: 'c64fe9cc9c1b5c17e442b729d1c7ca3a',
}

export default config;